<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8">
        <h2>Add Order</h2>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8">
        <label>Order name</label>
        <input v-model="newOrder.name" type="text">
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8">
        <label>Purchase order number</label>
        <input v-model="newOrder.poNumber" type="text">
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8">
        <label>Required date</label>
        <input v-model="newOrder.date" type="text">
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8">
        <button v-on:click="orderSubmitted = true" class="button">Submit</button>
      </div>
    </div>
    <div class="grid-x grid-padding-x" v-if="orderSubmitted === true">
      <div class="cell small-12 medium-8">
        <h2>Thank you for your order</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrdersAdd',
  data() {
    return {
      newOrder: {
        name: '',
        poNumber: '',
        date: '',
      },
      orderSubmitted: false,
    };
  },
  methods: {
    saveOrder() {},
  },
};
</script>
