<template>
  <div>
    <div class="pad-top">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <h2>
            Upload artwork to despatch ref: {{ order.despatch_ref }} / {{ order.order_no }}
          </h2>
        </div>
      </div>
      <div class="pad-top" v-for="n in thisPosition" :key="n">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-3">
            <p class="text-blue">Position {{ n }}</p>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-3">
            <p>DST</p>
            <app-uploader
              :type="`dst_${n}`"
              :process="order.order_lines[0].processes[0].id"></app-uploader>
            <span v-if="order.order_lines[0].processes[n -1].files.dst.length > 0">
              <a target="_blank" :href="order.order_lines[0].processes[n -1].files.dst">
                Download {{ order.order_lines[0].processes[n -1].files.dst_name }}
              </a>
            </span>
          </div>
          <div class="cell small-12 medium-3">
            <p>Runsheet</p>
            <app-uploader
              :type="`runsheet_${n}`"
              :process="order.order_lines[0].processes[0].id"></app-uploader>
            <span v-if="order.order_lines[0].processes[n -1].files.runsheet.length > 0">
              <a target="_blank" :href="order.order_lines[0].processes[n -1].files.runsheet">
                Download {{ order.order_lines[0].processes[n -1].files.runsheet_name }}
              </a>
            </span>
          </div>
          <div class="cell small-12 medium-3">
            <p>Order sheet</p>
            <app-uploader
              :type="`ordersheet_${n}`"
              :process="order.order_lines[0].processes[0].id"></app-uploader>
            <span v-if="order.order_lines[0].processes[n -1].files.order_sheet.length > 0">
              <a target="_blank" :href="order.order_lines[0].processes[n -1].files.order_sheet">
                Download {{ order.order_lines[0].processes[n -1].files.order_sheet_name }}
              </a>
            </span>
          </div>
          <div class="cell small-12 medium-3">
            <p>Other files</p>
            <app-uploader
              :type="`otherfiles_${n}`"
              :process="order.order_lines[0].processes[0].id"></app-uploader>
            <span v-if="order.order_lines[0].processes[n -1].files.other_file.length > 0">
              <a target="_blank" :href="order.order_lines[0].processes[n -1].files.other_file">
                Download {{ order.order_lines[0].processes[n -1].files.other_file_name }}
              </a>
            </span>
          </div>
        </div>
        <div class="grid-x grid-padding-x pad-top">
          <div class="cell small-12 medium-3">
            <label>Badge ref</label>
            <input type="text" v-model="badgeNumbers[n-1]" placeholder="Type reference here">
          </div>
          <div class="cell small-12 medium-3" style="padding-top: 37px">
            <button
              @click="saveBadgeRef(n)"
              class="button small">
              Save
            </button>
          </div>
        </div>
        <div
          v-if="(n + 1) === nextPosition"
          class="grid-x grid-padding-x">
          <div class="cell small-12 medium-3">
            <i
              v-on:click="addPosition"
              title="Add position"
              class="material-icons icon--blue icon-link">
              add_circle
            </i>
            <span
              v-on:click="addPosition"
              class="small-link">
              Add position {{ nextPosition }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="pad-top">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <router-link class="button" to="/orders/index">View Orders</router-link>
        </div>
      </div>
    </div>
    <app-message
      v-if="showMessage === true"
      :messageText="message"
      :link="false"
      :linkText="false"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Message from '@/components/Messages/Message.vue';
import Uploader from '@/views/Uploader/Uploader.vue';
import axios from '../../axios';

export default {
  name: 'OrdersUploadArtwork',
  components: {
    appMessage: Message,
    appUploader: Uploader,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      badgeNumbers: [],
      order: {
        order_lines: [
          {
            processes: [
              {},
            ],
          },
        ],
      },
      thisPosition: 1,
      nextPosition: 2,
      message: '',
      showMessage: false,
    };
  },
  methods: {
    saveBadgeRef(n) {
      const postData = {};
      postData.number = n;
      postData.badgeRef = this.badgeNumbers[n - 1];
      // eslint-disable-next-line
      postData.line = this.order.order_lines[0];
      axios.post(`/processes/saveBadgeRef.json?token=${this.token}`, postData)
        .then((response) => {
          this.message = response.data.message;
          this.showMessage = true;
        })
        .catch(() => {
          this.message = 'Sorry, the badge ref could not be added.';
          this.showMessage = true;
        });
    },
    addPosition() {
      this.thisPosition += 1;
      this.nextPosition += 1;
    },
    getOrder() {
      const orderId = this.$route.params.id;
      axios.get(`/orders/getSingle/${orderId}.json?token=${this.token}`)
        .then((response) => {
          this.order = response.data.order;
          for (let i = 0; this.order.order_lines[0].processes.length > i; i += 1) {
            this.badgeNumbers[i] = this.order.order_lines[0].processes[i].name;
            if (i > 0) {
              this.thisPosition += 1;
              this.nextPosition += 1;
            }
          }
        })
        .catch(() => {
          this.message = 'Sorry, the order could not be found.';
          this.showMessage = true;
        });
    },
  },
  mounted() {
    this.getOrder();
  },
};
</script>
