<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8">
        <h2>Users</h2>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Contact number</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Bridget Kagoshima</td>
              <td>b.kagoshima@pumateamwear.com</td>
              <td>+44(0)845 680 1204</td>
              <td>
                <i
                  v-on:click="archiveUser"
                  title="Archive user"
                  class="material-icons icon--green icon-link">
                  exit_to_app
                </i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UsersIndex',
  methods: {
    archiveUser() {},
  },
};
</script>
