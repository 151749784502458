<template>
  <div class="pad-top">
    <div class="grid-x grid-padding-x pad-top--small">
      <div class="cell small-12 medium-3">
        <h2 style="position:relative; top:-10px;">Orders</h2>
      </div>
      <div class="cell small-12 medium-6">
        <input v-model="searchTerm" type="text" placeholder="Search despatch ref">
      </div>
      <div class="cell small-12 medium-1">
        <button
          v-on:click="search"
          class="button expanded">Go</button>
      </div>
      <div class="cell small-12 medium-2">
        <button
          v-on:click="clearSearch"
          class="button expanded">Clear</button>
      </div>
    </div>
    <div
      class="grid-x grid-padding-x">
      <div class="cell small-12">
        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Badge ref</th>
              <th>Desptach ref</th>
              <th>Order ref</th>
              <th>Garment quantity</th>
              <th>No. positions</th>
              <th>Embroidery complete</th>
              <th>Postcode</th>
              <th>Box No.</th>
              <th>Tracking No.</th>
              <th class="text-center" colspan="3">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(order, index) in orders" :key="index">
              <td class="zoomable-wrap">
                <img class="zoomable-image" :src="order.runsheet" alt="" />
              </td>
              <td>{{ order.badge_no }}</td>
              <td>{{ order.despatch_ref }}</td>
              <td>{{ order.order_ref }}</td>
              <td>{{ order.product_quantity }}</td>
              <td>{{ order.positions }}</td>
              <td>
                <span v-if="order.embroidery_complete === 1 || order.embroidery_complete === true">
                  Yes
                </span>
                <span v-else>No</span>
              </td>
              <td>{{ order.postcode }}</td>
              <td>{{ order.box_no }}</td>
              <td>{{ order.tracking_no }}</td>
              <td>
                <i
                  v-if="order.dispatched !== true"
                  v-on:click="uploadArtwork(order.orderId)"
                  title="Upload files"
                  class="material-icons icon--blue icon-link">
                  launch
                </i>
                <span
                  class="small-link"
                  v-if="order.dispatched !== true"
                  v-on:click="uploadArtwork(order.orderId)">
                  Add files
                </span>
              </td>
              <td>
                <i
                  v-if="order.dispatched !== true"
                  title="Expedite order"
                  v-on:click="expediteOrder(order.orderId)"
                  class="material-icons icon--yellow icon-link">
                  alarm
                </i>
              </td>
              <td>
                <i
                  v-if="order.dispatched !== true"
                  title="Stop order"
                  v-on:click="stopOrder(order.orderId)"
                  class="material-icons icon--red icon-link">
                  pan_tool
                </i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'OrdersIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      orders: [],
      searchTerm: '',
    };
  },
  methods: {
    clearSearch() {
      this.searchTerm = '';
      this.getOrders();
    },
    search() {
      const postData = {};
      postData.searchTerm = this.searchTerm;
      axios.post(`/orders/getSearch/${this.userid}.json?token=${this.token}`, postData)
        .then((response) => {
          this.orders = response.data.orders;
        })
        .catch(() => {
          this.message = 'Sorry, the orders did not load.';
          this.showMessage = true;
        });
    },
    getOrders() {
      axios.get(`/orders/getAll/${this.userid}.json?token=${this.token}`)
        .then((response) => {
          this.orders = response.data.orders;
        })
        .catch(() => {
          this.message = 'Sorry, the orders did not load.';
          this.showMessage = true;
        });
    },
    stopOrder(id) {
      this.$router.push(`/orders/stop/${id}`);
    },
    expediteOrder(id) {
      this.$router.push(`/orders/expedite/${id}`);
    },
    uploadArtwork(id) {
      this.$router.push(`/orders/upload-artwork/${id}`);
    },
  },
  mounted() {
    setTimeout(() => {
      this.getOrders();
    }, 300);
  },
};
</script>

<style lang="scss" scoped>
.zoomable-wrap {
  position: relative;
}
.zoomable-image {
  max-width: 30px;
  cursor: pointer;
  &:hover {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    width: 800px;
    max-width: 800px;
  }
}
</style>
