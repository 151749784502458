import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login.vue';
import History from '@/views/History/History.vue';
import HistoryIndex from '@/views/History/HistoryIndex.vue';
import HistoryView from '@/views/History/HistoryView.vue';
import Orders from '@/views/Orders/Orders.vue';
import OrdersAdd from '@/views/Orders/OrdersAdd.vue';
import OrdersExpedite from '@/views/Orders/OrdersExpedite.vue';
import OrdersIndex from '@/views/Orders/OrdersIndex.vue';
import OrdersStop from '@/views/Orders/OrdersStop.vue';
import OrdersUploadArtwork from '@/views/Orders/OrdersUploadArtwork.vue';
import OrdersView from '@/views/Orders/OrdersView.vue';
import Uploader from '@/views/Uploader/Uploader.vue';
import Users from '@/views/Users/Users.vue';
import UsersIndex from '@/views/Users/UsersIndex.vue';
import UsersView from '@/views/Users/UsersView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/request-password',
    name: 'RequestPassword',
    component: () => import('../views/auth/RequestPassword.vue'),
  },
  {
    path: '/reset-password/:hash/:userid',
    name: 'ResetPassword',
    component: () => import('../views/auth/ResetPassword.vue'),
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    children: [
      {
        path: 'index',
        name: 'HistoryIndex',
        component: HistoryIndex,
      },
      {
        path: 'view/:id',
        name: 'HistoryView',
        component: HistoryView,
      },
    ],
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    children: [
      {
        path: 'add',
        name: 'Add',
        component: OrdersAdd,
      },
      {
        path: 'expedite/:id',
        name: 'Expedite',
        component: OrdersExpedite,
      },
      {
        path: 'index',
        name: 'Index',
        component: OrdersIndex,
      },
      {
        path: 'stop/:id',
        name: 'Stop',
        component: OrdersStop,
      },
      {
        path: 'upload-artwork/:id',
        name: 'OrdersUploadArtwork',
        component: OrdersUploadArtwork,
      },
      {
        path: 'view/:id',
        name: 'View',
        component: OrdersView,
      },
    ],
  },
  {
    path: '/uploader',
    name: 'Uploader',
    component: Uploader,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    children: [
      {
        path: 'index',
        name: 'UsersIndex',
        component: UsersIndex,
      },
      {
        path: 'view/:id',
        name: 'UsersView',
        component: UsersView,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
