<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h2>Order {{ order.order_no }}</h2>
        <p class="lead">This order is currently due to be delivered in 1 working day</p>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-7">
        <ul>
          <li
            v-for="(line, index) in order.lines"
            v-bind:key="index">
            {{ line }}
          </li>
        </ul>
        <div class="pad-top">
          <router-link tag="button" to="/orders/stop/1" class="button alert">
            Stop Order
          </router-link>
          <router-link tag="button" to="/orders/expedite/1" class="button">
            Expedite Order
          </router-link>
        </div>
      </div>
      <div class="cell small-12 medium-5">
        <ul class="timeline">
          <li class="complete order-received">Order Received 18th October 2019 at 10.36am</li>
          <li class="complete order-received">Stock Received 19th October 2019 at 12.23pm</li>
          <li class="current order-received">Garments in pallet area 1</li>
          <li class="future production">Machine 2 22rd October 2019 at 9.45am</li>
          <li class="future dispatch">Dispatched 23rd October 2019 at 11.15am</li>
          <li class="future delivered">Delivered 24rd October 2019 at 12.05pm</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrdersView',
  data() {
    return {
      order: {
        order_no: 0,
        lines: [],
      },
      expedited: false,
    };
  },
  methods: {
    setup() {
      const orderId = this.$route.params.id;
      // eslint-disable-next-line
      console.log(orderId);
      if (orderId === '1') {
        this.order.order_no = 'RUN_61950';
        this.order.lines = [
          'Puma team shirt L and XL',
          'Puma team shirt, S and M',
          'Puma team shirt L',
        ];
      }
      if (orderId === '2') {
        this.order.order_no = 'RUN_61953';
        this.order.lines = [
          'Puma team shirt S',
          'Puma team shirt L',
          'Puma team shirt X',
          'Puma team shirt M',
          'Puma team shirt L',
        ];
      }
      if (orderId === '3') {
        this.order.order_no = 'RUN_61951';
        this.order.lines = [
          'Puma team shirt X',
        ];
      }
    },
  },
  mounted() {
    this.setup();
  },
};
</script>
