<template>
  <div class="message">
    <div class="text">
      {{ messageText }}
    </div>
    <div class="actions">
      <router-link
        v-if="link"
        :to="link">
        <button class="button small">{{ linkText }}</button>
      </router-link>
      &nbsp;
      <button
        @click="dismissMessage"
        class="button small">Dismiss</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Message',
  props: [
    'messageText',
    'link',
    'linkText',
  ],
  methods: {
    dismissMessage() {
      this.$emit('closeMessage');
    },
  },
};
</script>

<style lang="scss">
  .message {
    position: fixed;
    top: 33%;
    left: 50%;
    z-index: 2;
    margin-left: -300px;
    width: 600px;
    padding: 20px;
    border: 1px solid #CCC;
    border-radius: 3px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 140px;
    animation: fadeIn 100ms;
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  .text {
    padding-bottom: 10px;
    text-align: center;
  }

  .actions {
    position: absolute;
    right: 5px;
    bottom: 5px;
  }
</style>
