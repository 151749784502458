<template>
  <div class="pad-top">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8">
        <h2>Expedite Order {{ order.order_no }}</h2>
        <p class="lead">
          This order is currently due to be despatched on {{ order.delivery_date | prettyDate }}
        </p>
        <p>
          Please select the date you would like:
          <datepicker v-model="date"></datepicker>
        </p>
        <p>
          We will contact you with a revised delivery date and cost for fast tracking this order
        </p>
        <button class="button" v-on:click="expediteOrder">Expedite Now</button>
      </div>
    </div>
    <app-message
      v-if="showMessage === true"
      :messageText="message"
      :link="false"
      :linkText="false"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Message from '@/components/Messages/Message.vue';
import axios from '../../axios';

export default {
  name: 'OrdersExpedite',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  components: {
    Datepicker,
    appMessage: Message,
  },
  data() {
    return {
      date: new Date(),
      order: {},
      message: '',
      showMessage: false,
    };
  },
  methods: {
    getOrder() {
      const orderId = this.$route.params.id;
      axios.get(`/orders/getSingle/${orderId}.json?token=${this.token}`)
        .then((response) => {
          this.order = response.data.order;
        })
        .catch(() => {
          this.message = 'Sorry, the order could not be found.';
          this.showMessage = true;
        });
    },
    expediteOrder() {
      const postData = {};
      postData.date = this.date;
      postData.orderId = this.$route.params.id;
      axios.post(`/orders/expediteOrder.json?token=${this.token}`, postData)
        .then(() => {
          this.message = 'We have received your request to rush this order and will be in touch shortly.';
          this.showMessage = true;
        })
        .catch(() => {
          this.message = 'Sorry, the order could not be expedited.';
          this.showMessage = true;
        });
    },
  },
  mounted() {
    this.getOrder();
  },
};
</script>
