<template>
  <div>
    <div
      class="grid-x grid-padding-x mobile-header pad-top--small pad-bottom--small">
      <div class="cell small-9">
        <img
          width="200"
          src="../../../static/logos/runsmart_logo.png"
          alt="" />
      </div>
      <div class="cell small-3">
        <button
          v-on:click="toggleMobileNav"
          data-direction="open"
          class="navigation__trigger">&nbsp;</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SystemTopNavigation',
  computed: {
    mobileNavOpen() {
      return this.$store.getters.mobileNavOpen;
    },
  },
  methods: {
    toggleMobileNav(event) {
      const direction = event.currentTarget.getAttribute('data-direction');
      this.$store.dispatch('toggleMobileNav', { direction });
    },
  },
};
</script>
