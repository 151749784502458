import Vue from 'vue';
import Vuex from 'vuex';
import Localbase from 'localbase';

const db = new Localbase('db');
db.config.debug = false;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appTitle: process.env.VUE_APP_TITLE,
    token: '',
    userid: '',
    username: '',
    authChecked: false,
  },
  mutations: {
    markAuthChecked(state) {
      state.authChecked = true;
    },
    setCredentials(state, credentials) {
      state.token = credentials.json.token;
      state.userid = credentials.user;
      state.username = credentials.name;
    },
    unsetCredentials(state) {
      state.token = '';
      state.userid = '';
      state.username = '';
    },
    useCredentials(state, credentials) {
      state.token = credentials.token;
      state.userid = credentials.id;
      state.username = credentials.name;
    },
  },
  actions: {
    setCredentials({ commit }, credentials) {
      commit('setCredentials', credentials);
      db.collection('users').doc({ id: credentials.user }).get().then((document) => {
        if (document) {
          db.collection('users').doc({ id: document.id }).set({
            id: credentials.user,
            name: credentials.name,
            token: credentials.json.token,
          });
        } else {
          db.collection('users').add({
            id: credentials.user,
            name: credentials.name,
            token: credentials.json.token,
          });
        }
      });
    },
    unsetCredentials({ commit }) {
      commit('unsetCredentials');
      db.collection('users').delete();
    },
  },
  getters: {},
});
