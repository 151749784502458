<template>
  <div class="dashboard-container">
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed, 'mobile-open': mobileNavOpen }"
      v-bind:style="{ 'height': computedHeight + 'px' }"
      class="side-navigation__container">
      <app-side-nav></app-side-nav>
    </div>
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed }"
      v-bind:style="{ 'height': computedHeight + 'px' }"
      class="module__container">
      <app-header></app-header>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import SideNav from '@/components/SystemNavigation/DashboardNav.vue';
import Header from '@/components/Header/SystemTopNavigation.vue';

export default {
  name: 'History',
  components: {
    'app-side-nav': SideNav,
    'app-header': Header,
  },
  computed: {
    sideNavClosed() {
      return this.$store.getters.sideNavClosed;
    },
    computedHeight() {
      return this.$store.getters.computedHeight;
    },
  },
  methods: {
    mobileNavOpen() {
      return this.$store.getters.mobileNavOpen;
    },
  },
};
</script>
