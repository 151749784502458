<template>
  <div>
    <div :class="`uppy_${type}`"></div>
    <div :class="`progress_${type}`"></div>
    <app-message
      v-if="showMessage === true"
      :messageText="message"
      :link="false"
      :linkText="false"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Message from '@/components/Messages/Message.vue';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Dropbox from '@uppy/dropbox';
import GoogleDrive from '@uppy/google-drive';
import ProgressBar from '@uppy/progress-bar';
import Transloadit from '@uppy/transloadit';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import axios from '../../axios';

export default {
  name: 'Uploader',
  props: [
    'type',
    'process',
  ],
  components: {
    appMessage: Message,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      message: '',
      showMessage: false,
    };
  },
  methods: {
    saveToServer(fileData) {
      const postData = {};
      postData.files = fileData;
      postData.type = this.type;
      postData.process = this.process;
      axios.post(`/artworkFiles/addFiles.json?token=${this.token}`, postData)
        .then((response) => {
          this.showMessage = true;
          this.message = response.data.message;
        });
    },
  },
  mounted() {
    const uppy = new Uppy({ debug: true, autoProceed: true });
    uppy.use(Dashboard, {
      id: `id_${this.type}`,
      target: `.uppy_${this.type}`,
      trigger: null,
      inline: true,
      width: 300,
      height: 350,
      hideUploadButton: false,
      plugins: [
        'Dropbox',
      ],
    });
    uppy.use(Dropbox, {
      target: Dashboard,
      companionUrl: 'https://companion.uppy.io/',
    });
    uppy.use(GoogleDrive, {
      target: Dashboard,
      companionUrl: 'https://companion.uppy.io/',
    });
    uppy.use(ProgressBar, {
      target: `.progress_${this.type}`,
      hideAfterFinish: false,
    });
    uppy.use(Transloadit, {
      service: 'https://api2.transloadit.com',
      params: {
        auth: { key: 'db6a1d40944f11e5a0a165fb32f6e49b' },
        template_id: '2e948eff56094d4a8faa3cd08dcd5a29',
      },
      waitForEncoding: true,
      waitForMetadata: false,
      importFromUploadURLs: false,
      alwaysRunAssembly: false,
      signature: null,
    });
    uppy.on('complete', (result) => {
      this.saveToServer(result);
    });
  },
};
</script>

<style lang="scss">
  .uppy-FileInput-container {
    margin-bottom: 0;
  }

  .uppy-FileInput-btn {
    font-size: 0;
    width: 100%;
    background-color: #FFF;
    border: none;
    padding: 5px 10px;
    border: 2px solid #FFF;
    box-shadow: 5px 1px 5px #DDD;

    &::before {
      content: 'Upload files';
      font-size: 16px;
    }
  }

  .UppyProgressBar {
    position: relative;
    top: -3px;
    width: 95%;
    margin-left: 2.5%;
  }
</style>
