<template>
  <div class="grid-container pad-top--xl">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-4 medium-offset-4">
        <img src="../../static/logos/runsmart_logo.png" alt="" />
        <h4 class="pad-top">Login</h4>
        <form>
          <label>Email</label>
          <input
            name="email"
            type="text"
            v-model="email">
          <label>Password</label>
          <input
            name="password"
            label="Password"
            type="password"
            v-model="password">
        </form>
        <button @click="tryLogin" class="button">Login</button>
        &nbsp;
        <router-link to="/request-password">
          <span class="link">Forgot password</span>
        </router-link>
      </div>
    </div>
    <app-message
      v-if="showMessage === true"
      :messageText="message"
      :link="false"
      :linkText="false"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Message from '@/components/Messages/Message.vue';
import axios from '../axios';

export default {
  name: 'Login',
  components: {
    appMessage: Message,
  },
  computed: {
    userid() {
      return this.$store.state.userid;
    },
  },
  watch: {
    userid() {
      this.testAuth();
    },
  },
  data() {
    return {
      email: '',
      failed: false,
      hide: true,
      message: '',
      password: '',
      showMessage: false,
    };
  },
  methods: {
    testAuth() {
      if (this.userid !== '') {
        console.log('var');
        this.$router.push('/orders/index');
      } else {
        this.hide = false;
      }
    },
    tryLogin() {
      const postData = {};
      postData.email = this.email;
      postData.password = this.password;
      axios.post('/users/login.json', postData)
        .then((response) => {
          this.$store.dispatch('setCredentials', response.data);
          this.$router.push('/orders/index');
        })
        .catch(() => {
          this.message = 'Sorry, the email or password was incorrect.';
          this.showMessage = true;
        });
    },
  },
  mounted() {
    this.testAuth();
  },
};
</script>
