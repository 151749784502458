<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h2>Order RU_34637</h2>
        <p class="lead">This order is complete</p>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-7">
        <ul>
          <li>20 x Puma team shirt S White with 1 x Embroidery</li>
          <li>20 x Puma team shirt M White with 1 x Embroidery</li>
          <li>20 x Puma team shirt L White with 1 x Embroidery</li>
          <li>20 x Puma team shirt XL White with 1 x Embroidery</li>
        </ul>
      </div>
      <div class="cell small-12 medium-5">
        <ul class="timeline">
          <li class="complete order-received">Order Received 1st Aug 2019 at 2.26pm</li>
          <li class="complete order-received">Stock Received 5th Aug 2019 at 10.14pm</li>
          <li class="complete order-received">
            Garments in pallet area One 5th Aug 2019 at 10.40pm
          </li>
          <li class="complete production">Production 6th Aug 2019 at 5.32am</li>
          <li class="complete dispatch">Dispatched 6th Aug 2019 at 10.18am</li>
          <li class="complete delivered">Delivered 7th Aug 2019 at 11.48am</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrdersView',
  data() {
    return {
      expedited: false,
    };
  },
};
</script>
