<template>
  <div>
    <app-styles></app-styles>
    <router-view></router-view>
  </div>
</template>

<script>
import Styles from '@/components/Ztyles/Styles.vue';
import Localbase from 'localbase';
import axios from './axios';

export default {
  name: 'App',
  components: {
    'app-styles': Styles,
  },
  computed: {
    showHeader() {
      let showHeader = true;
      if (this.$route.name === 'Login') showHeader = false;
      if (this.$route.name === 'RequestPassword') showHeader = false;
      return showHeader;
    },
  },
  methods: {
    checkForCredentials() {
      const db = new Localbase('db');
      db.config.debug = false;
      db.collection('users').get().then((users) => {
        if (users[0]) {
          this.$store.commit('useCredentials', users[0]);
          // test if these are still valid
          const testToken = users[0].token;
          axios.post(`/users/testCredentials.json?token=${testToken}`)
            .then(() => {
              this.$store.commit('markAuthChecked');
              console.log('Authenticated');
            })
            .catch((error) => {
              console.log(error);
              this.$store.commit('markAuthChecked');
              this.$store.dispatch('unsetCredentials');
            });
        } else {
          this.$store.commit('markAuthChecked');
          // this.$store.dispatch('unsetCredentials');
          // this.$router.push('/');
        }
      });
    },
  },
  mounted() {
    this.checkForCredentials();
  },
};
</script>
