<template>
  <div
    class="pad-top--small">
    <router-link to="/dashboard" tag="a" class="system-nav__logo"> </router-link>
    <nav
      v-bind:class="{ 'mobile-open': mobileNavOpen }"
      class="side-navigation">
      <ul class="side-navigation__group">
        <router-link
          to="/orders/index"
          title="Dashboard"
          tag="li"
          class="side-navigation__item">
          <i class="material-icons">web</i>
          Orders
        </router-link>
        <span
          @click="logout"
          class="side-navigation__item">
          <i class="material-icons">settings_power</i>
          Log Out
        </span>
        <!--
          <router-link
            to="/users/index"
            title="Dashboard"
            tag="li"
            class="side-navigation__item">
            <i class="material-icons">assignment_ind</i>
            Manage Users
          </router-link>
        -->
      </ul>
    </nav>
  </div>
</template>

<script>
import Localbase from 'localbase';

export default {
  name: 'DashboardNav',
  data() {
    return {
      computedHeight: 0,
      searchForm: false,
      expediteOrder: false,
      stopOrder: false,
    };
  },
  computed: {
    mobileNavOpen() {
      return this.$store.getters.mobileNavOpen;
    },
  },
  methods: {
    logout() {
      const db = new Localbase('db');
      db.config.debug = false;
      db.delete().then(() => {
        this.$store.commit('unsetCredentials');
        window.location.replace('/');
      });
    },
  },
};
</script>
