<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8">
        <h2>Order History</h2>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <table>
          <thead>
            <tr>
              <th>Order no</th>
              <th>Dispatched</th>
              <th class="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>RU_357565</td>
              <td>29th Aug 2019</td>
              <td class="text-right">
                <i
                  v-on:click="viewOrder"
                  class="material-icons icon--green icon-link">
                  visibility
                </i>
              </td>
            </tr>
            <tr>
              <td>RU_357568</td>
              <td>27th Aug 2019</td>
              <td class="text-right">
                <i
                  v-on:click="viewOrder"
                  class="material-icons icon--green icon-link">
                  visibility
                </i>
              </td>
            </tr>
            <tr>
              <td>RU_357535</td>
              <td>29th Aug 2019</td>
              <td class="text-right">
                <i
                  v-on:click="viewOrder"
                  title="View order"
                  class="material-icons icon--green icon-link">
                  visibility
                </i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HistoryIndex',
  data() {
    return {
      expedited: false,
    };
  },
  methods: {
    viewOrder() {
      this.$router.push('/history/view/1');
    },
  },
};
</script>
