import axios from 'axios';

export default axios.create({
  // Dev
  // baseURL: 'https://runsmart-clients-api.mmandb-dev.co.uk',
  // Production
  baseURL: 'https://clients-api.runsmart.co.uk',
  headers: {
    'Content-type': 'application/json',
  },
});
