<template>
  <div class="pad-top">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8">
        <h2>Stop Order {{ order.order_no }}</h2>
        <h4>Please enter a reason to stop this order</h4>
        <textarea v-model="emailMessage"></textarea>
        <button class="button" v-on:click="stopOrder">Stop Now</button>
      </div>
    </div>
    <app-message
      v-if="showMessage === true"
      :messageText="message"
      :link="false"
      :linkText="false"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Message from '@/components/Messages/Message.vue';
import axios from '../../axios';

export default {
  name: 'OrdersStop',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  components: {
    appMessage: Message,
  },
  data() {
    return {
      emailMessage: '',
      message: '',
      order: {},
      showMessage: false,
    };
  },
  methods: {
    getOrder() {
      const orderId = this.$route.params.id;
      axios.get(`/orders/getSingle/${orderId}/${this.userid}.json?token=${this.token}`)
        .then((response) => {
          this.order = response.data.order;
        })
        .catch(() => {
          this.message = 'Sorry, the order could not be found.';
          this.showMessage = true;
        });
    },
    stopOrder() {
      const postData = {};
      postData.id = this.$route.params.id;
      postData.message = this.emailMessage;
      axios.post(`/orders/stopOrder.json?token=${this.token}`, postData)
        .then(() => {
          this.message = 'We have received your request to stop this order and will be in touch shortly.';
          this.showMessage = true;
        })
        .catch(() => {
          this.message = 'Sorry, the order could not be stopped.';
          this.showMessage = true;
        });
    },
  },
  mounted() {
    this.getOrder();
  },
};
</script>
